import React from 'react'
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'

export const HomePageTemplate = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content

  return (
    <>
      <section className="header section">
        <div className="header-container  container">
          <h1 className="has-text-weight-bold is-size-2">{title}</h1>
          <PageContent className="content" content={content} />
        </div>
      </section>
    </>
  );
};

class HomePage extends React.Component {
  render() {
    const { data } = this.props;
    const {
      data: { footerData, navbarData },
    } = this.props;
    const home = data.homePageData.edges[0].node;
    return (
      <Layout footerData={footerData} navbarData={navbarData}>
        <Helmet>
          <meta name="title" content={home.frontmatter.title} />
          <meta name="description" content={home.frontmatter.description} />
          <title>{home.frontmatter.title}</title>
        </Helmet>
        <HomePageTemplate 
          contentComponent={HTMLContent}
          title={home.frontmatter.title}
          content={home.html}
        />
      </Layout>
    );
  }
}

HomePage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default HomePage;

export const pageQuery = graphql`
  query HomePageQuery {
    homePageData: allMarkdownRemark(filter: { frontmatter: { templateKey: { eq: "home-page" } } }) {
      edges {
        node {
          html
          frontmatter {
            title
            description
          }
        }
      }
    }
  }
`
